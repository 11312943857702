(function () {
    'use strict';

    var serviceId = 'campaignService';
    angular.module('app').factory(serviceId, ['appCache', 'api', '$rootScope', 'context', 'authService', 'hub', 'userSettingsService', '$q', '$filter', '$timeout', '$window', 'expiringAppCache', 'page', 'config', campaignServiceFactory]);

    function campaignServiceFactory(appCache, api, $rootScope, context, authService, hub, userSettingsService, $q, $filter, $timeout, $window, expiringAppCache, page, config) {
        var service = {
            getSelectedMarketerId: function () {
                var marketerId = appCache.get('selectedMarketerId');
                return marketerId;
            },
            excludeMoneyFormat: function () {
                return {
                    'spend': '$6', 'onlinePurchaseAmount': '$', 'offlinePurchaseAmount': '$', 'totalPurchaseAmount': '$', 'offlineAdjustedAmount': '$', 'totalAdjustedSales': '$', 'incrementalSales': '$',
                    'onlinePurchaseAmount': '$', 'viewOnlinePurchaseAmount': '$', 'clickOnlinePurchaseAmount': '$',
                    'offlinePurchaseAmount': '$', 'viewOfflinePurchaseAmount': '$', 'clickOfflinePurchaseAmount': '$',
                    'totalViewPurchaseAmount': '$', 'totalClickPurchaseAmount': '$',
                    'averageCPM': '$6', 'mediaAverageCPM': '$6', 'mediaCost': '$6', 'marketerMargin': '$6',
                    'fullCartOnlinePurchaseAmount': '$', 'fullCartOfflinePurchaseAmount': '$', 'fullCartTotalPurchaseAmount': '$', 'fullCartOfflineAdjustedAmount': '$', 'fullCartTotalAdjustedSales': '$', 'fullCartIncrementalSales': '$',
                    'fullCartOnlinePurchaseAmount': '$', 'fullCartViewOnlinePurchaseAmount': '$', 'fullCartClickOnlinePurchaseAmount': '$',
                    'fullCartOfflinePurchaseAmount': '$', 'fullCartViewOfflinePurchaseAmount': '$', 'fullCartClickOfflinePurchaseAmount': '$',
                    'fullCartTotalViewPurchaseAmount': '$', 'fullCartTotalClickPurchaseAmount': '$'
                };
            },

            setSelectedMarketerId: function (marketerId) {

                if (appCache.get('selectedMarketerId') !== marketerId) {
                    appCache.put("selectedMarketerId", marketerId);
                    //use broadcast  on rootScope to send event to underlying scopes
                    if ($rootScope != null)
                        $rootScope.$broadcast('newSelectedMarketerId', marketerId);
                }

            },
            removeSelectedMarketerId: function () {
                appCache.remove('selectedMarketerId');
            },
            clearCampaignVariables: function () {
                this.removeSelectedMarketerId();
                this.removeSelectedCampaign();
                this.removeSelectedAdGroup();
            },
            getCurrencies: function () {
                return [
                    { id: 1, name: 'USD' },
                    { id: 2, name: 'GBP' }
                ];
            },
            getChannels: function () {
                return [
                    { id: 1, name: 'Banner', estimatedMediaCPM: 5 },
                    { id: 2, name: 'Video', estimatedMediaCPM: 10 },
                    { id: 5, name: 'Audio', estimatedMediaCPM: 0 },
                    { id: 4, name: 'Connected TV', estimatedMediaCPM: 30 },
                    { id: 3, name: 'Facebook/Instagram', estimatedMediaCPM: 2 },
                    { id: 6, name: 'Email', estimatedMediaCPM: 0 },
                    { id: 7, name: 'Search', estimatedMediaCPM: 0 },
                    { id: 8, name: 'Twitter', estimatedMediaCPM: 0 },
                    { id: 9, name: 'Data-Only', estimatedMediaCPM: 0 },
                    { id: 10, name: 'Measurement', estimatedMediaCPM: 0 },
                    { id: 11, name: 'LinkedIn', estimatedMediaCPM: 0 },
                    { id: 12, name: 'Xandr Curate', estimatedMediaCPM: 0 },
                    { id: 13, name: 'TikTok', estimatedMediaCPM: 0 },
                    { id: 14, name: 'Snapchat', estimatedMediaCPM: 0 },
                    { id: 15, name: 'Xandr Data Market Place', estimatedMediaCPM: 0 },
                    { id: 16, name: 'Endemic HCP', estimatedMediaCPM: 250 },
                    { id: 17, name: 'TradeDesk Data Market Place', estimatedMediaCPM: 0 },
                    { id: 18, name: 'Live Ramp', estimatedMediaCPM: 0 },
                    { id: 19, name: "Direct Sold", estimatedMediaCPM: 0 },
                    { id: 20, name: "Endemic DTC", estimatedMediaCPM: 0 },
                    { id: 21, name: 'Reddit', estimatedMediaCPM: 0 },
                    { id: 22, name: 'Yahoo', estimatedMediaCPM: 0 },
                    { id: 23, name: 'Adtheorent', estimatedMediaCPM: 0 },
                    { id: 25, name: 'EHR', estimatedMediaCPM: 250 }
                ];
            },
            getMediaTypes: function() {
                return [
                    { id: 1, name: 'Banner' },
                    { id: 4, name: 'E-Newsletter' }
                ];
            },
            getTriggerEngagements: function () {
                return [
                    { id: 1, name: 'View' },
                    { id: 2, name: 'Click' },
                    { id: 3, name: 'Custom' },
                    { id: 4, name: 'Un-Open' },
                    { id: 5, name: 'Open' },
                    { id: 6, name: 'Click' }
                ];
            },
            getSourceTypes: function () {
                return [
                    { id: 1, name: 'Programmatic' },
                    { id: 2, name: 'Email' }
                ];
            },
            getChannelEvents: function () {
                return [
                    { id: 0, name: 'None' },
                    { id: 1, name: 'Target Email Unopened' },
                    { id: 2, name: 'Target Email Opened' },
                    { id: 3, name: 'Target Email Clicked' },
                    { id: 4, name: 'Target User who Viewed Ad' },
                    { id: 5, name: 'Target User who Clicked Ad' }
                ];
            },
            getAudienceStatuses: function () {
                return [
                    { id: 1, name: 'Processing' },
                    { id: 2, name: 'Ready' },
                    { id: 3, name: 'Failed' },
                    { id: 4, name: 'Draft' }
                ];
            },
            getAudienceTypes: function () {
                return [
                    { id: 1, name: "Prescriber" },
                    { id: 2, name: "Professional" },
                    { id: 3, name: "Custom" },
                    { id: 4, name: "Brand Safety" },
                    { id: 5, name: "Demographics" },
                    { id: 6, name: "Consumer" },
                    { id: 7, name: "Location" },

                ];
            },
            getPrograms: function () {
                return [
                    { id: 1, name: 'National' },
                    { id: 2, name: 'Coop' }
                ];
            },
            getSizes: function () {
                return [
                    { id: 0, name: 'No Size' },
                    { id: 1, name: '728x90' },
                    { id: 2, name: '300x250' },
                    { id: 3, name: '160x600' },
                    { id: 4, name: '320x50' },
                    { id: 5, name: '300x600' },
                    { id: 6, name: '300x50' },
                    { id: 7, name: '970x90' },
                    { id: 8, name: '970x250' },
                    { id: 9, name: '1x1' },
                    { id: 10, name: '460x120' },
                    { id: 11, name: '570x70' },
                    { id: 12, name: '120x240' },
                    { id: 13, name: '650x136' },
                    { id: 14, name: '1200x628' },
                    { id: 15, name: '320x480' },
                    { id: 16, name: '640x480' },
                    { id: 17, name: '640x640' },
                    { id: 18, name: '120x600' },
                    { id: 19, name: '1920x1080' },
                    { id: 20, name: '768x1024' },
                    { id: 21, name: '1024x768' },
                    { id: 22, name: '320x100' },
                    { id: 23, name: '300x1050' },
                    { id: 24, name: '250x250' },
                    { id: 25, name: '336x280' },
                    { id: 26, name: '480x320' },
                    { id: 27, name: '320x250' },
                    { id: 28, name: '970x550' },
                    { id: 29, name: '468x60' },
                ];
            },
            getCreativeTypes: function () {
                return [
                    { id: 1, name: 'Single Image' },
                    { id: 2, name: 'Carousel' },
                    //{ id: 3, name: 'Collection/Canvas' },
                    { id: 4, name: 'Single Video' }
                ];
            },
            getRecencyTimeUnits: function () {
                return [
                    { id: 1, name: 'Minutes' },
                    { id: 2, name: 'Hours' },
                    { id: 3, name: 'Days' },
                    { id: 4, name: 'Weeks' },
                    { id: 5, name: 'Months' }
                ];
            },
            getFrequencyTimeUnits: function () {
                return [
                    { id: 1, name: 'Lifetime' },
                    { id: 2, name: 'Hour' },
                    { id: 3, name: 'Day' },
                    { id: 4, name: 'Week' },
                    { id: 5, name: 'Month' }
                ];
            },
            getOwners: function () {
                return [
                    { id: 0, name: 'Advertiser' },
                    { id: 1, name: 'Admin' },
                    { id: 2, name: 'Creative Team' },
                    { id: 3, name: 'Internal' }
                ];
            },
            getStatuses: function () {
                return [
                    { id: 0, name: 'Draft' },
                    { id: 10, name: 'In Review' },
                    { id: 30, name: 'Approved' },
                    { id: 40, name: 'Rejected' },
                    { id: 50, name: 'Active' },
                    { id: 60, name: 'Inactive' },
                    { id: 70, name: 'Removed' }
                ];
            },
            getCampaignStatuses: function () {
                return [
                    { id: 1, name: 'Active', priority: 4 },
                    { id: 3, name: 'Paused', priority: 5 },
                    { id: 10, name: 'Inactive', priority: 0 },
                    { id: 15, name: 'Archived', priority: 0 }
                ];
            },
            getRecencyValues: function () {
                return [
                    { id: 1, name: '0 days', value: 0 },
                    { id: 2, name: '7 days', value: 7 },
                    { id: 3, name: '14 days', value: 14 },
                    { id: 4, name: '30 days', value: 30 },
                    { id: 5, name: '90 days', value: 90 },
                    { id: 6, name: '180 days', value: 180 }
                    //{ id: 7, name: '1 year', value: 365 }
                ];
            },
            getCampaignAudienceTypes: function () {
                return [
                    { id: 1, name: 'Marketer Audience' },
                    { id: 2, name: 'Advertiser Audience' },
                    { id: 3, name: 'Combination Audience' }
                ];
            },
            getBooleanOperators: function () {
                return [
                    { id: 1, name: 'AND' },
                    { id: 2, name: 'OR' }
                ];
            },
            getAccounts: function (accountType, reload) {
                var deferred = $q.defer();
                var that = this;
                context.webapi.getAccounts(reload).then(function (ds) {
                    appCache.put('accounts', ds);

                    ds.accounts.filter(function (a) { return a.accountType == 2; }).forEach(function (r) {
                        r.marketersOptions = [];
                        r.marketersOptions.push({
                            name: r.name,
                            taxonomyId: r.taxonomyId,
                            options: ds.accountOptions.filter(function (ao) { return ao.accountId == r.id; })
                        });
                        r.marketersOptions[0].options.forEach(function (o) {
                            Object.assign(o, ds.accountOptionTypes.find(function (aot) { return o.optionId == aot.id; }));
                        });
                        r.options = r.marketersOptions[0].options;
                        r.linkedAccount = r.linkedAccountId ? ds.accounts.find(function (la) { return la.id == r.linkedAccountId; }) : null;
                        r.marketerContract = ds.marketerContracts.find(function (rc) { return rc.marketerId == r.id; });
                    });


                    ds.accounts.filter(function (a) { return a.accountType == 1; }).forEach(function (b) {
                        b.marketer = ds.accounts.find(function (m) { return b.marketerId == m.id; });
                        b.options = ds.accountOptions.filter(function (ao) { return ao.accountId == b.id; });
                        b.options.forEach(function (o) {
                            Object.assign(o, ds.accountOptionTypes.find(function (aot) { return o.optionId == aot.id; }));
                        });
                    });


                    that.accounts = ds.accounts;

                    that.accounts.sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        } else if (a.name > b.name) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    that.tdxAccounts = ds.accounts.filter(function (a) { return !a.accountType; });
                    that.advertisers = ds.accounts.filter(function (a) { return a.accountType == 1; });
                    that.marketers = ds.accounts.filter(function (a) { return a.accountType == 2; });
                    that.publishers = ds.accounts.filter(function (a) { return a.accountType == 5; });

                    if (accountType == 1)
                        deferred.resolve(that.advertisers);
                    else if (accountType == 2)
                        deferred.resolve(that.marketers);
                    else if (accountType == 5)
                        deferred.resolve(that.publishers);
                    else if (!accountType)
                        deferred.resolve(that.accounts);
                },
                    function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            canShowPlatformFees: function (campaignId, account) {
                var advertiserMarketerSettings;
                if (campaignId) {
                    var camp = this.getSelectedCampaign();
                    if (camp) {
                        advertiserMarketerSettings = account.advertiserMarketers ? account.advertiserMarketers.find(function (br) { return br.marketerId == camp.marketerId; }) : null;
                        return advertiserMarketerSettings ? advertiserMarketerSettings.advertiserCanSeeMediaPlatformFees : false;
                    }
                    else
                        return false;
                }
                else {
                    advertiserMarketerSettings = account.advertiserMarketers ? account.advertiserMarketers.find(function (br) { return br.advertiserCanSeeMediaPlatformFees == true; }) : null;
                    return advertiserMarketerSettings != null;
                }
            },
            tdxAccounts: [],
            advertisers: [],
            marketers: [],
            manufacturers: [],
            marketerHoldingCompanies: [],
            getCreatives: function (advertiserId, marketerId, page, keyword, filter, sortColumn, sortDescending, singlePage, exchangeSeatId, isAdGroup) {
                var deferred = $q.defer();
                var that = this;
                if (!filter)
                    filter = { channels: [], sizes: [], statuses: [], owners: [], marketers: [], creativeTypes: [], advertisers: [] };

                context.webapi.getCreatives(advertiserId, marketerId, page, keyword, filter, sortColumn, sortDescending, singlePage, exchangeSeatId, isAdGroup).then(function (d) {
                    //appCache.put('creatives', d);
                    that.creatives = d;
                    deferred.resolve(that.creatives);
                },
                    function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            creatives: {},
            getCreativeRequests: function (page, keyword, sortColumn, sortDescending) {
                var deferred = $q.defer();
                var that = this;
                context.webapi.getCreativeRequests(page, keyword, sortColumn, sortDescending).then(function (d) {
                    that.creativeRequests = d;
                    deferred.resolve(that.creativeRequests);
                },
                    function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            creativeRequests: {},
            getSelectedMarketer: function () {
                var marketers = this.marketers;
                var selectedMarketerId = this.getSelectedMarketerId();
                var partner;
                if (marketers) {
                    partner = _.find(marketers, function (r) { return r.id == selectedMarketerId });
                }
                return partner;
            },
            getSelectedCampaign: function () {
                var campaign = appCache.get('selectedCampaign');
                if (campaign)
                    return campaign;
            },
            setSelectedCampaign: function (campaign, adGroup, edit) {
                if (appCache.get('selectedAdGroup') !== adGroup && adGroup) {
                    appCache.put("selectedAdGroup", adGroup);
                }
                const cached = appCache.get('selectedCampaign');

                const hasChanged = campaign != cached;

                if (hasChanged) {
                    appCache.put("selectedCampaign", campaign);
                    var campaignName = campaign == 'All Campaigns' ? 'All Campaigns' : (campaign ? campaign.name : 'No campaign');
                }

                if ($rootScope != null && !edit)
                    $rootScope.$broadcast('campaignSelected', campaign);
            },
            removeSelectedCampaign: function () {
                appCache.remove('selectedCampaign');
            },
            getSelectedAdGroup: function () {
                var adGroup = appCache.get('selectedAdGroup');
                if (adGroup)
                    return adGroup;
            },
            setSelectedAdGroup: function (adGroup, campaign) {
                if (appCache.get('selectedCampaign') !== campaign && campaign) {
                    appCache.put("selectedCampaign", campaign);
                }

                if (appCache.get('selectedAdGroup') !== adGroup) {
                    appCache.put("selectedAdGroup", adGroup);
                    var adGroupName = adGroup ? adGroup.name : 'No adGroup';
                }

                if ($rootScope != null)
                    $rootScope.$broadcast('adGroupSelected', adGroup);
            },
            removeSelectedAdGroup: function () {
                appCache.remove('selectedAdGroup');
            },
            getCampaignNames: function (advertiserId) {
                var deferred = $q.defer();
                var that = this;
                api.get(`api/Campaign/campaignNames?advertiserId=${advertiserId}`).then((x) => {
                    that.campaignNames = x;
                    deferred.resolve(x);
                }, (error) => {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getAdGroupNames: function (marketerId, advertiserId, campaignId, keyword, existingRetargeting) {
                var deferred = $q.defer();
                var that = this;
                var query = `api/AdGroup/adGroupNames?marketerId=${marketerId}&advertiserId=${advertiserId}&campaignId=${campaignId}&keyword=${keyword}`;
                if (existingRetargeting)
                    query = query + '&existingRetargeting=true';
                api.get(query).then((x) => {
                    that.adGroupNames = x;
                    deferred.resolve(x);
                }, (error) => {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getSourceAdGroups: function (marketerId, advertiserId, campaignId, keyword, sourceTypeId, adGroupIds) {
                var deferred = $q.defer();
                var that = this;
                var query = `api/AdGroup/sourceAdGroups?marketerId=${marketerId}&advertiserId=${advertiserId}&campaignId=${campaignId}&keyword=${keyword}&sourceTypeId=${sourceTypeId}&adGroupIds=${adGroupIds}`;
                api.get(query).then((x) => {
                    that.adGroupNames = x;
                    deferred.resolve(x);
                }, (error) => {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            campaignNames: [],
            adGroupNames: [],
            restoreObject: function (header, elt)
            {
                let r = {};
                for (let i = 0; i < header.length; i++) {
                    switch (typeof header[i]) {
                        case 'string': r[header[i]] = elt[i]; break;
                        case 'object': r[header[i][0]] = typeof header[i][1] == 'object' && header[i][1].length ? this.restoreArray(header[i][1], elt[i]) : this.restoreObject(header[i], elt[i]); break;
                    }
                }
                return r;
            },
            restoreArray: function (header, rows) {
                let result = [];
                for (const elt of rows) {
                    result.push(this.restoreObject(header, elt));
                }
                return result;
            },
            getCampaignsList: function (reload, keyword) {
                var deferred = $q.defer();
                var that = this;
                //copy campaigns and adgroups to arrays
                if (!keyword)
                    keyword = '';
                expiringAppCache.get({
                    url: `api/Campaign/list-jst?keyword=${keyword}`
                }).then((x) => {
                    const channel = that.getChannels().reduce((acc, x) => {
                        acc[x.id] = x.name;
                        return acc;
                    }, {});
                    that.campaigns = this.restoreArray(x.header, x.rows);
                    that.adGroups = [];

                    that.campaigns.forEach(function (c) {
                        _.each(c.adGroups,
                            function (a) {
                                a["chan"] = channel[a.channelId];
                                that.adGroups.push(a);
                            });
                    });
                    deferred.resolve(that.campaigns);
                }, (error) => {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getLast30CampaignSummary: function (campaignId) {
                return this.getCampaignSummary(campaignId);
            },
            getCampaignSummary: function (campaignId, range = 'Last30Days') {
                return expiringAppCache.get({
                    url: `api/reports/campaignsummary/campaign/${campaignId}/${range}/3`
                });
            },
            getCampaignStatusNameById: function (statusId) {
                var name = '';
                this.getCampaignStatuses().forEach(function (item, index) {
                    if (item.id == statusId)
                        name = item.name;
                });
                return name;
            },

            addReportingDatesForExport: function (csvData) {
                csvData.push([""]);
                csvData.push([""]);
                if (this.campaignsStatistic.dateRange) {
                    var dateFrom = this.campaignsStatistic.dateRange.from;
                    var dateTo = this.campaignsStatistic.dateRange.to;
                    var token = this.campaignsStatistic.dateRange.dimentionToken;
                    //Sliding ranges fix
                    if (token == 'K' || token == 'L' || token == 'J') {
                        var SLIDING_RANGES = { K: 30, L: 90, J: 7 };
                        dateTo = new Date(new Date().toDateString())
                        dateTo = new Date(dateTo.setDate(dateTo.getDate() - 1));
                        dateFrom = new Date(dateTo.getTime() - (24 * 60 * 60 * 1000 * (SLIDING_RANGES[token] - 1)));

                    }
                    csvData.push(["Start Date: " + $filter('date')(dateFrom, 'MM/dd/yyyy (EST)', 'UTC')]);
                    csvData.push(["End Date: " + $filter('date')(dateTo, 'MM/dd/yyyy (EST)', 'UTC')]);
                }

                if (this.campaignsStatistic.reportingUpdates && this.campaignsStatistic.reportingUpdates.length > 0 && csvData && csvData.length > 0) {
                    csvData.push(["Campaign data updated through: " + $filter('date')(this.campaignsStatistic.reportingUpdates.a, 'MM/dd/yyyy h:mm a (EST)', 'UTC')]);
                    csvData.push(["Purchase data updated through: " + $filter('date')(this.campaignsStatistic.reportingUpdates.p, 'MM/dd/yyyy h:mm a (EST)', 'UTC')]);
                    csvData.push(["Advertiser Page Visit data updated through: " + $filter('date')(this.campaignsStatistic.reportingUpdates.v, 'MM/dd/yyyy h:mm a (EST)', 'UTC')]);

                }
                csvData.push(["Report run at:  " + $filter('date')(new Date(), 'MM/dd/yyyy h:mm a (EST)', 'EST')]);
                csvData.push(["(C) Lasso, " + new Date().getFullYear()]);

            },
            campaigns: [],
            adGroups: [],
            reportingUpdates: null,
            liveOnly: false,
            getAllAdGroupsStatistic: function (reload, page, keyword, filter, liveOnly, sortColumn, sortDescending, currentPageCampaigns, isExport) {
                var service = this;
                var deferred = $q.defer();
                var that = this;
                if (liveOnly)
                    this.liveOnly = true;

                var func = function (result) {
                    const campaigns = that.filterCampaigns(filter, result, currentPageCampaigns);
                    const campaignIds = campaigns.map(c => c.campaignId);
                    service.getAccounts(2).then(function (marketers) {
                        var marketerIds = [];
                        if (marketers) {
                            marketerIds = _.map(marketers, 'id');
                        }
                        liveOnly = liveOnly ? 1 : 0;
                        var adGroups = that.adGroups;
                        if (filter) {
                            adGroups = _.filter(adGroups, function (ag) {
                                return campaignIds.indexOf(ag.campaignId) > -1;
                            })
                            if (liveOnly) {
                                adGroups = adGroups.filter((ag) => {
                                    return ag.adGroupStatusId == 1;
                                });
                            } else {
                                adGroups = adGroups.filter((ag) => {
                                    return filter.adGroupStatuses.length == 0 ||
                                        filter.adGroupStatuses.find((s) => {
                                            return s == ag.adGroupStatusId;
                                        });
                                });
                            }
                            if (filter.channels) {
                                adGroups = adGroups.filter((ag) => {
                                    return filter.channels.length == 0 ||
                                        filter.channels.find((c) => {
                                            return c == ag.channelId || c == 10 && ag.isMeasurementOnly;
                                        });
                                });
                            }
                        }

                        result.forEach(function (c) {
                            adGroups.forEach(function (a) {
                                if (a.campaignId === c.campaignId) {
                                    a.advertiserName = c.advertiserName;
                                    a.mark = c.marketerName;
                                    a.campaignName = c.name;
                                    a.budgetTypeId = c.budgetTypeId;
                                }
                            });
                        });

                        if (keyword) {
                            keyword = keyword.toLowerCase();
                            adGroups = _.filter(adGroups, function (ag) { return ag.name.toLowerCase().includes(keyword) || ag.adGroupId == keyword; });
                        }

                        var ids = _.map(adGroups, 'adGroupId');

                        var currentPageIds = null;// currentPageCampaigns && !isExport ? _.map(currentPageCampaigns, 'campaignId') : null;
                        context.stat.allAdGroupsSummary(ids, null, page, sortColumn, sortDescending, currentPageIds, isExport).then(function (stat) {
                            that.fullBasketChanged(stat);
                            if (stat && stat.reportingUpdates) {

                                $rootScope.rootVM.reportingUpdates = stat.reportingUpdates;
                            }
                            else {
                                $rootScope.rootVM.reportingUpdates = null;

                            }

                            if (stat && stat.results && stat.results[0]) {
                                var statElem = stat.results[0];
                                adGroups.forEach(function (item, idx) {
                                    for (var attrname in statElem) {
                                        //bypass marketer value
                                        if (attrname != 'ret' && attrname != 'chan' && item[attrname] == null)
                                            item[attrname] = 0;
                                    }
                                });

                                adGroups.forEach(function (item, idx) {
                                    var st = _.filter(stat.results, { 'adGroupId': item.adGroupId });//stat.filter(function (s) { return s.campaignId == item.campaignId; })
                                    if (st != null && st.length > 0) {
                                        for (var attrname in st[0]) {

                                            if (!item[attrname])
                                                item[attrname] = st[0][attrname];
                                        }

                                    }
                                    //fix to copy marketer name to ret value
                                    item.ret = item.marketerName;
                                    item.status = service.getStatusNameById(item.adGroupStatusId);
                                });

                                var adGroupsStatistic = {
                                    results: adGroups,
                                    header: stat.header,
                                    reportingUpdates: stat.reportingUpdates,
                                    dateRange: stat.dateRange
                                };
                                that.adGroupsStatistic = adGroupsStatistic;

                                context.stat.allAdGroupsSummaryTotals(ids, isExport).then(function (header) {
                                    that.adGroupsStatistic.header = header;
                                    if (!isExport)
                                        $rootScope.$broadcast('adGroupsSummaryTotalsLoaded', header);
                                });

                                deferred.resolve(adGroupsStatistic);
                            }
                            else {
                                that.adGroupsStatistic.results = [];
                                that.adGroupsStatistic.header = {};
                                that.adGroupsStatistic.dateRange = null;
                                that.adGroupsStatistic.reportingUpdates = null;
                                deferred.resolve(adGroupsStatistic);
                            }
                        });
                    });
                }

                var campaign = this.getSelectedCampaign();
                if (typeof (campaign) === "string")
                    service.getCampaignsList(reload, '', liveOnly).then(func);
                else
                    func([campaign]);

                return deferred.promise;
            },
            getExportSort: function () {
                return {
                    sortColumn: "adGroupId",
                    sortDescending: true
                };
            },
            getPagedAdGroupsStatistic: function ({ filter, progress, sortColumn, sortDescending, keyword, pageNumber, pageSize = config.pageSize(), dateRange, campaignId, isExport, adGroupIds }) {
                var service = this;
                var deferred = $q.defer();
                var that = this;

                if (isExport) {
                    pageSize = that.adGroupsStatistic.count;
                    pageNumber = 0;
                }

                const exportSort = that.getExportSort();

                const req = {
                    sortColumn: isExport ? exportSort.sortColumn : sortColumn,
                    sortDescending: isExport ? exportSort.sortDescending : sortDescending,
                    parameters: { "ids": adGroupIds || [], filter: dateRange },
                    pageSize,
                    pageNumber,
                    filter: { progress, keyword, campaignId, filterLists: { ...filter } }
                };
                api.post(`api/statistics/pagedadgroups/`, req).then((x) => {
                    const { adGroups, stats, total, campaigns, counter } = x;
                    const originalAdGroups = [];
                    if (!isExport)
                        that.adGroups = adGroups;

                    adGroups.forEach(function (item, idx) {
                        const campaign = campaigns.find(c => c.campaignId == item.campaignId);
                        item.advertiserName = campaign.advertiserName;
                        item.advertiserId = campaign.advertiserId; // Copy advertiserId to adGroup to help creative-list
                        item.marketerId = campaign.marketerId; // Copy marketerId to adGroup to help creative-list
                        item.budgetTypeId = campaign.budgetTypeId; // Copy budgetTypeId to adGroup because history needs it
                        item.campaignName = campaign.name;

                        item.marketerId = campaign.marketerId;
                        item.marketerName = campaign.marketerName;
                        item.mark = campaign.marketerName;
                        originalAdGroups.push({ ...item });

                        const st = stats.results.find(s => s.adGroupId === item.adGroupId);
                        if (st != null) {
                            for (var attrname in st) {
                                if (!item[attrname] || attrname == "viewability")
                                    item[attrname] = st[attrname];
                            }
                        }
                        item.ret = item.marketerName;
                        item.status = service.getStatusNameById(item.adGroupStatusId);
                    });

                    var adGroupsStatistic = {
                        originalAdGroups,
                        total,
                        counter,
                        campaigns,
                        count: stats.count,
                        results: adGroups,
                        header: stats.header,
                        reportingUpdates: stats.reportingUpdates,
                        dateRange: stats.dateRange
                    };
                    if (!isExport)
                        that.adGroupsStatistic = adGroupsStatistic;
                    else
                        that.exportAdGroupsStatistic = adGroupsStatistic;

                    deferred.resolve(adGroupsStatistic);


                }, (error) => {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getCampaignConceptGroupsExport: function (campaignId) {
                var deferred = $q.defer();
                api.get(`api/Campaign/get-concepts/${campaignId}`).then((x) => {
                    deferred.resolve(x);
                }, (error) => {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getBulkExport: function ({ filter, progress, sortColumn, sortDescending, keyword, pageNumber, pageSize = config.pageSize(), dateRange, campaignId }) {
                const sortExport = this.getExportSort();
                const req = {
                    sortColumn: sortExport.sortColumn,
                    sortDescending: sortExport.sortDescending,
                    parameters: { "ids": [], filter: dateRange },
                    pageSize,
                    pageNumber,
                    filter: { progress, keyword, campaignId, filterLists: { ...filter } }
                };
                return api.post(`api/statistics/bulkexport/`, req).then((x) => {
                    console.log(x);
                    return x;
                })

            },
            filterCampaigns: function (filter, campaigns, currentPageCampaigns) {
                return _.filter(campaigns, function (c) {
                    return (!currentPageCampaigns || currentPageCampaigns.find(function (cc) { return c.campaignId == cc; })) &&
                        (filter.programs.length == 0 || filter.programs.find(function (p) { return p == c.programId || c.programId == 3; })) &&
                        (filter.marketers.length == 0 || filter.marketers.find(function (r) { return r == c.marketerId; })) &&
                        (filter.advertisers.length == 0 || filter.advertisers.find(function (r) { return r == c.advertiserId; })) &&
                        (filter.accountManagers.length == 0 || filter.accountManagers.find(function (a) { return a == c.accountManagerId; }));
                });
            },
            fullBasketChanged: function (stat) {
                if (stat.isFullBasket != userSettingsService.getFullBasket()) {
                    if (authService.isLoggedIn())
                        hub.getLog('campaigns', 'error')('Full Basket Mode has changed. Page Refresh is required');

                    $timeout(function () {
                        $window.location.reload();
                    }, 5000);
                }
            },

            adGroupsStatistic: { results: [], header: {}, reportingUpdates: null, dateRange: null },
            getCampaigns: function ({ pageNumber = 0, keyword, filter, pageSize = config.pageSize(), sortColumn, sortDescending, isExport, dateRange }) {
                var service = this;
                var deferred = $q.defer();
                var that = this;

                if (isExport) {
                    pageSize = that.pagedCampaignsStatistic.count;
                    pageNumber = 0;
                }

                service.getPagedCampaignsList({ filter, dateRange, keyword, pageNumber, pageSize, sortColumn, sortDescending })
                    .then(function (result) {
                        const campaigns = result.campaigns;
                        const stat = result.stats;
                        const total = result.total;

                        campaigns.forEach(function (c) {
                            c.isLegacy = false;
                            c.mark = c.marketerName;
                        });

                        that.fullBasketChanged(stat);

                        if (stat && stat.reportingUpdates) {

                            $rootScope.rootVM.reportingUpdates = stat.reportingUpdates;
                        }
                        else {
                            $rootScope.rootVM.reportingUpdates = null;
                        }

                        if (stat && stat.results && stat.results[0]) {
                            var statElem = stat.results[0];
                            campaigns.forEach(function (item, idx) {
                                for (var attrname in statElem) {
                                    //bypass marketer value
                                    if (attrname != 'ret' && attrname != 'chan' && item[attrname] == null)
                                        item[attrname] = 0;
                                }
                            });

                            campaigns.forEach(function (item, idx) {

                                var st = _.filter(stat.results, { 'campaignId': item.campaignId });
                                if (st != null && st.length > 0) {
                                    for (var attrname in st[0]) {

                                        if (!item[attrname])
                                            item[attrname] = st[0][attrname];
                                    }

                                }
                                //fix to copy marketer name to ret value
                                item.ret = item.marketerName;

                                var audienceType = service.getCampaignAudienceTypes().find(function (at) { return item.audienceTypeId == at.id; });
                                item.audienceType = audienceType ? audienceType.name : '';

                                item.status = service.getStatusNameById(item.campaignStatusId);
                            });

                            var pagedCampaignsStatistic = {
                                results: campaigns,
                                header: stat.header,
                                reportingUpdates: stat.reportingUpdates,
                                dateRange: stat.dateRange,
                                count: stat.count,
                                pageNumber,
                                pageSize
                            };
                            if (!isExport) {
                                that.pagedCampaignsStatistic = pagedCampaignsStatistic;
                                that.pagedCampaignsStatistic.header = total;
                                $rootScope.$broadcast('campaignsSummaryTotalsLoaded', total);
                            }
                            else {
                                that.exportCampaignsStatistic = pagedCampaignsStatistic;
                                that.exportCampaignsStatistic.header = total;
                            }

                            deferred.resolve(pagedCampaignsStatistic);
                        }
                        else {
                            var pagedCampaignsStatistic = {
                                results: [],
                                header: {},
                                dateRange: null,
                                reportingUpdates: null,
                            }
                            that.pagedCampaignsStatistic = pagedCampaignsStatistic;

                            deferred.resolve(pagedCampaignsStatistic);
                        }
                    });
                return deferred.promise;

            },
            getPagedCampaignsList: function ({ filter, sortColumn, sortDescending, keyword, pageNumber, pageSize, dateRange }) {
                var deferred = $q.defer();
                var that = this;

                const req = {
                    sortColumn,
                    sortDescending,
                    parameters: { "ids": [], filter: dateRange },
                    pageSize,
                    pageNumber,
                    filter: { keyword, filterLists: { ...filter } }
                };
                api.post(`api/statistics/pagedcampaigns/`, req).then((x) => {
                    that.campaigns = x.campaigns;
                    deferred.resolve(x);
                }, (error) => {
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            getCampaignsStatistic: function (reload, page, keyword, filter, liveOnly, sortColumn, sortDescending, currentPageCampaigns, isExport) {
                var service = this;
                var deferred = $q.defer();
                var that = this;
                if (liveOnly)
                    this.liveOnly = true;
                service.getCampaignsList(reload, keyword, liveOnly)
                    .then(function (result) {
                        service.getAccounts(2).then(function (marketers) {
                            var marketerIds = [];
                            if (marketers) {
                                marketerIds = _.map(marketers, 'id');
                            }
                            liveOnly = liveOnly ? 1 : 0;
                            var campaigns = _.filter(result,
                                function (c) {
                                    return marketerIds.indexOf(c.marketerId) > -1 &&
                                        (liveOnly ? c.campaignStatusId === liveOnly : true);
                                });

                            var campaignsForSidebar = campaigns;

                            if (filter) {
                                campaigns = that.filterCampaigns(filter, campaigns);
                                campaigns = _.filter(campaigns, function (c) {
                                    return (filter.statuses.length == 0 || filter.statuses.find(function (s) { return s == c.campaignStatusId; }))
                                });
                            }

                            campaigns.forEach(function (c) {
                                c.isLegacy = c.adGroups.filter(function (ag) { return ag.isLegacy; }).length !== 0;
                            });

                            var ids = _.map(campaigns.filter(function (c) { return !liveOnly || c.campaignStatusId == 1; }), 'campaignId');

                            var currentPageIds = null;
                            context.stat.campaignsSummary(ids, null, page, sortColumn, sortDescending, currentPageIds, isExport).then(function (stat) {
                                that.fullBasketChanged(stat);

                                if (stat && stat.reportingUpdates) {

                                    $rootScope.rootVM.reportingUpdates = stat.reportingUpdates;
                                }
                                else {
                                    $rootScope.rootVM.reportingUpdates = null;

                                }

                                if (stat && stat.results && stat.results[0]) {
                                    var statElem = stat.results[0];
                                    campaigns.forEach(function (item, idx) {
                                        for (var attrname in statElem) {
                                            //bypass marketer value
                                            if (attrname != 'ret' && attrname != 'chan' && item[attrname] == null)
                                                item[attrname] = 0;
                                        }
                                    });

                                    campaigns.forEach(function (item, idx) {

                                        var st = _.filter(stat.results, { 'campaignId': item.campaignId });
                                        if (st != null && st.length > 0) {
                                            for (var attrname in st[0]) {

                                                if (!item[attrname])
                                                    item[attrname] = st[0][attrname];
                                            }

                                        }
                                        //fix to copy marketer name to ret value
                                        item.ret = item.marketerName;

                                        var audienceType = service.getCampaignAudienceTypes().find(function (at) { return item.audienceTypeId == at.id; });
                                        item.audienceType = audienceType ? audienceType.name : '';

                                        item.status = service.getStatusNameById(item.campaignStatusId);
                                    });

                                    var campaignsStatistic = {
                                        results: campaigns,
                                        campaignsForSidebar: campaignsForSidebar,
                                        header: stat.header,
                                        reportingUpdates: stat.reportingUpdates,
                                        dateRange: stat.dateRange
                                    };
                                    that.campaignsStatistic = campaignsStatistic;

                                    context.stat.campaignsSummaryTotals(ids, isExport).then(function (header) {
                                        that.campaignsStatistic.header = header;
                                        if (!isExport)
                                            $rootScope.$broadcast('campaignsSummaryTotalsLoaded', header);
                                    });

                                    deferred.resolve(campaignsStatistic);
                                }
                                else {
                                    that.campaignsStatistic.results = [];
                                    that.campaignsStatistic.header = {};
                                    that.campaignsStatistic.dateRange = null;
                                    that.campaignsStatistic.reportingUpdates = null;
                                    deferred.resolve(campaignsStatistic);
                                }
                            });
                        });
                    });
                return deferred.promise;

            },
            campaignsStatistic: { results: [], campaignsForSidebar: [], header: {}, reportingUpdates: null, dateRange: null },
            getStatusNameById: function (statusId) {
                var service = this;
                var name = '';
                service.getCampaignStatuses().forEach(function (item, index) {
                    if (item.id == statusId)
                        name = item.name;
                });
                return name;
            },

            getAdgroupTotals: function (adgroups, original) {
                var total = {};
                total.adGroupId = null;
                total.impressions = page.getTotal(adgroups, 'impressions', 0);
                total.clicks = page.getTotal(adgroups, 'clicks', 0);
                total.conversions = page.getTotal(adgroups, 'conversions', 0);
                total.spend = page.getTotal(adgroups, 'spend');
                total.averageFrequency = page.getTotal(adgroups, 'averageFrequency', 0);
                total.averageCPM = page.divideTotals(adgroups, 'spend', 'impressions') * 1000;
                total.ctr = total.impressions > 0 ? total.clicks / total.impressions : 0;
                total.adGroupSettingCPMRate = total.impressions > 0 ? total.spend / (total.impressions / 1000) : 0;
                //revert to original totals
                if (original) {
                    total.averageFrequency = original.averageFrequency;
                    total.uniqueMessaged = original.uniqueMessaged;
                    total.costPerUnit = original.costPerUnit;
                    total.conversionRate = original.conversionRate;
                    total.visitRate = original.visitRate;
                }
                else {
                    total.averageFrequency = null;
                    total.uniqueMessaged = null;
                    total.costPerUnit = null;
                    total.conversionRate = null;
                    total.visitRate = null;
                }
                total.marketerMargin = page.getTotal(adgroups, 'marketerMargin', 0);
                total.marketerMarginPercentage = total.spend ? total.marketerMargin / total.spend : 0;
                total.mediaCost = page.getTotal(adgroups, 'mediaCost', 0);
                total.mediaAverageCPM = total.impressions ? 1000 * total.mediaCost / total.impressions : 0;
                total.deliveryCost = page.getTotal(adgroups, 'deliveryCost');
                total.dataCPM = page.divideTotals(adgroups, 'deliveryCost', 'impressions') * 1000;
                total.postClickConversions = page.getTotal(adgroups, 'postClickConversions');
                total.postViewConversions = page.getTotal(adgroups, 'postViewConversions');
                return total;
            },

            getCampaignFlights: function(campaignId) {
                return api.get(`api/Campaign/flights?campaignId=${campaignId}`);
            },
        };
        return service;

    }
})();

(function () {
    'use strict';

    var serviceId = 'pacingService';
    angular.module('app').factory(serviceId, [pacingServiceFactory]);

    const PACING_BAR_LENGTH = 355;
    const abs = Math.abs;
    const width = (x) => Math.min(1, abs(x)) * PACING_BAR_LENGTH;
    const advWidth = (x, y, max) =>  abs(x) >= abs(y) ? 0: width( (abs(x) - abs(y))/max);

    function pacingServiceFactory() {
        const setImpressionData = (adGroup) => {
            const max = Math.max(abs(adGroup.totalImpressionsToBeServed), abs(adGroup.impressionsShouldBeServed), abs(adGroup.impressions));
            const totalImpressionsToBeServed = abs(adGroup.totalImpressionsToBeServed);
            const impressionsShouldBeServed = abs(adGroup.impressionsShouldBeServed);
            const impressions = abs(adGroup.impressions);
            const actualImpressions = abs(adGroup.actualImpressions);
            adGroup.pacingBarChartData = {
                adGroupId: adGroup.adGroupId,
                impressions: width(adGroup.actualImpressions /max),
                impressionsShouldBeServed:  advWidth(adGroup.actualImpressions, adGroup.impressionsShouldBeServed, max),
                totalImpressionsToBeServed: Math.max(impressionsShouldBeServed, actualImpressions) >= totalImpressionsToBeServed ? 0 : (totalImpressionsToBeServed - Math.max(impressionsShouldBeServed, actualImpressions)) / max * PACING_BAR_LENGTH
            };

            if (adGroup.budgetCap < 0) {

                const max = Math.max(totalImpressionsToBeServed, impressionsShouldBeServed, impressions, actualImpressions);
                adGroup.pacingBarChartData = {
                    adGroupId: adGroup.adGroupId,
                    impressions: actualImpressions / max * PACING_BAR_LENGTH,
                    impressionsShouldBeServed: actualImpressions >= impressionsShouldBeServed ? 0 : (impressionsShouldBeServed - actualImpressions) / max * PACING_BAR_LENGTH,
                    totalImpressionsToBeServed: Math.max(impressionsShouldBeServed, actualImpressions) >= totalImpressionsToBeServed ? 0 : (totalImpressionsToBeServed - Math.max(impressionsShouldBeServed, actualImpressions)) / max * PACING_BAR_LENGTH
                };
            }
        }
        const setSpendData = (adGroup) => {
            let { isFlightLevel, channelId, budgetCapSpendModeId, startDate, endDate, budgetCap, flightId, flightBudgetCap, flightStartDate, flightEndDate } = adGroup;

            if (isFlightLevel) {
                startDate = flightStartDate;
                endDate = flightEndDate;
                budgetCap = flightBudgetCap;
            }
            var daysTotal = Math.ceil((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
            var daysRun;
            if (new Date(startDate) >= new Date())
                daysRun = 0;
            else
                daysRun = Math.ceil(((new Date(endDate) > new Date()) ? new Date() - new Date(startDate) : new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
            adGroup.totalBudget = (budgetCapSpendModeId == 1 && channelId != 6) ? budgetCap * daysTotal : budgetCap;
            adGroup.budgetShouldBeSpent = (budgetCapSpendModeId == 1) ? budgetCap * daysRun : budgetCap / daysTotal * daysRun;
            adGroup.actualSpend = adGroup.actualBudgetToDate;

            const max = Math.max(adGroup.totalBudget, adGroup.budgetShouldBeSpent, adGroup.actualSpend);
            adGroup.pacingBarChartData = {
                adGroupId: adGroup.adGroupId,
                actualSpend: Math.min(1, adGroup.actualSpend / max) * PACING_BAR_LENGTH,
                budgetShouldBeSpent: Math.min(1, adGroup.actualSpend >= adGroup.budgetShouldBeSpent ? 0 : (adGroup.budgetShouldBeSpent - adGroup.actualSpend) / max) * PACING_BAR_LENGTH,
                totalBudget: Math.max(adGroup.budgetShouldBeSpent, adGroup.actualSpend) >= adGroup.totalBudget ? 0 : (adGroup.totalBudget - Math.max(adGroup.budgetShouldBeSpent, adGroup.actualSpend)) / max * PACING_BAR_LENGTH
            };
        }
        const setPacingData = (adGroup) => {
            if (adGroup.budgetTypeId == 1) {
                setImpressionData(adGroup);
            }
            else if (adGroup.budgetTypeId == 2) {
                setSpendData(adGroup);
            }
        }

        var service = {
            setPacingData
        }
        return service;

    }
})();
